import axios from 'axios';
import getFormData from './getFormData';

class ApiClient {
  constructor() {
    const axiosApp = axios.create({
      baseURL: '/market',
      withCredentials: true,
    });

    axiosApp.interceptors.response.use(
      (response) => {
        if (response.data.data) {
          return response.data.data;
        }
        return response.data;
      },
      (error) => {
        if (error.response.data.errors) return Promise.reject(error.response.data.errors);
        return Promise.reject(error.response.data.message);
      },
    );

    this.axios = axiosApp;
  }

  async login(email, password) {
    const loginReq = await this.axios.post('/user/login', {
      email,
      password,
    });
    return loginReq;
  }

  async loginByToken(authToken) {
    const loginReq = await this.axios.post('/user/loginByToken', {
      authToken,
    });
    return loginReq;
  }

  async resetPassword(email) {
    const resetReq = await this.axios.post('/user/resetPassword', {
      email,
    });
    return resetReq;
  }

  async logout() {
    const data = await this.axios.post('/user/logout');
    return data;
  }

  async checkAuthentication() {
    const data = await this.axios.get('/user/isAuthenticated');
    return data;
  }

  async createConsultation(values) {
    const data = await this.axios.post('/consultation', values);
    return data;
  }

  async createConsultationPayment(values) {
    const data = await this.axios.post('/consultation/createWithPayment', values);
    return data;
  }

  async updateData(url, values = {}) {
    const formData = getFormData(values);
    const data = await this.axios.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async uploadDocument(type, values = {}) {
    const formData = getFormData({ ...values, type });
    const data = await this.axios.post('/specialist/uploaddoc', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  }

  async createData(url, values = {}) {
    const data = await this.axios.post(url, values);
    return data;
  }

  async getData({ url, query }) {
    let requestUri = url;
    if (query) {
      requestUri += `?${query}`;
    }
    const data = await this.axios.get(requestUri);
    return data;
  }
}

export default new ApiClient();
