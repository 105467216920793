import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { CContainer, CFade } from '@coreui/react';
import Preloader from '../components/Preloader';

const CleanContainer = (props) => {
  const { children } = props;

  return (
    <div className="c-app c-default-layout">
      <div className="c-wrapper">
        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <Suspense fallback={<Preloader />}>
                <CFade>
                  {children}
                </CFade>
              </Suspense>
            </CContainer>
          </main>
        </div>
      </div>
    </div>
  );
};

CleanContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CleanContainer;
