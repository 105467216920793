import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import { ReactComponent as StarSolidIcon } from '../assets/icons/coreui/solid/star.svg';

const StarRating = (props) => {
  const { onChange, length, value } = props;
  const [rating, setRating] = useState(value);
  const [hover, setHover] = useState(value);

  return (
    <div className="star-rating">
      <div className="star-rating__stars">
        {[...Array(length)].map((_, index) => {
          const val = index + 1;
          const isActive = (val <= (hover || rating));

          return (
            <button
              onClick={() => {
                setRating(val);
                onChange(val);
              }}
              className="p-1 star-rating__star"
              onMouseEnter={() => setHover(val)}
              onMouseLeave={() => setHover(rating)}
              type="button"
              key={val}
            >

              <StarSolidIcon className={`star-rating__star-icon-solid ${isActive ? 'star-rating__star-icon-solid_state_active' : ''}`} />
              <CIcon
                name="cil-star"
              />

            </button>
          );
        })}
      </div>
    </div>
  );
};

StarRating.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  length: PropTypes.number,
};

StarRating.defaultProps = {
  length: 5,
};

export default StarRating;
