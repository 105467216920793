import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInvalidFeedback,
  CTextarea,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CIcon from '@coreui/icons-react';
import ApiClient from '../../utils/ApiClient';
import StarRating from '../../components/StarRating';
import CleanContainer from '../../containers/CleanContainer';
import dateFormatDefault from '../../utils/dateFormatDefault';

const Review = () => {
  const { id: reviewHash } = useParams();
  const [catchError, setCatchError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [verifiedData, setVerifiedData] = useState(null);
  const [isReviewCreated, setIsReviewCreated] = useState(false);

  useEffect(() => {
    const verifyHash = async () => {
      try {
        const data = await ApiClient.getData({ url: `/review/verify/${reviewHash}` });

        setVerifiedData(data);
      } catch (e) {
        setCatchError(e);
      }
    };

    verifyHash();
  }, []);

  const validationSchema = () => Yup.object().shape({
    review: Yup.string()
      .required('Это поле необходимо заполнить'),
    rating: Yup.number()
      .min(0, 'Это поле необходимо заполнить'),
  });

  const handleSubmit = async (values, actions) => {
    try {
      setSubmitError(null);

      await ApiClient.createData(`/review/create/${reviewHash}`, {
        rating: values.rating,
        text: values.review,
      });

      setIsReviewCreated(true);

      actions.resetForm();
      actions.setSubmitting(false);
    } catch (e) {
      actions.setSubmitting(false);
      setSubmitError('При отправке отзыва произошла ошибка');
    }
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      review: '',
      rating: 5,
    },
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const renderContent = () => {
    if (isReviewCreated) {
      return (
        <div className="d-flex flex-column align-items-center text-center">
          <CIcon
            size="6xl"
            name="cil-check-circle"
            className="mb-4"
          />
          <h1>Отзыв успешно отправлен</h1>
        </div>
      );
    }

    if (catchError !== null) {
      return <h1>{catchError}</h1>;
    }

    if (verifiedData === null) return <div>da</div>;

    return (
      <>

        <CCol
          sm={12}
          // md={10}
          // lg={8}
          // xl={5}
          style={{
            maxWidth: 600,
          }}
        >

          <CCard>

            <CCardHeader>
              <h1>Отзыв на консультацию</h1>
              <h3>
                от:&nbsp;
                {dateFormatDefault(verifiedData.consultation.startDate)}
              </h3>
              <h3>
                Специалист:&nbsp;
                {verifiedData.specialist.fullname}
              </h3>
            </CCardHeader>

            <CCardBody>

              <CAlert fade show={(submitError !== null)} color="danger">{submitError}</CAlert>

              <CForm onSubmit={formik.handleSubmit} noValidate method="post">
                <CFormGroup>
                  <StarRating
                    onChange={(value) => {
                      formik.setValues({ ...formik.values, rating: value });
                    }}
                    value={formik.values.rating}
                  />
                </CFormGroup>

                <CFormGroup>
                  <CTextarea
                    rows={12}
                    style={{
                      resize: 'vertical',
                      minHeight: 200,
                    }}
                    onChange={(e) => {
                      if (!formik.touched.review) formik.setFieldTouched('review', true);
                      formik.handleChange(e);
                    }}
                    valid={(formik.touched.review) && !formik.errors.review}
                    invalid={(formik.touched.review) && !!formik.errors.review}
                    id="review"
                    name="review"
                    placeholder="Оставьте свой отзыв..."
                    value={formik.values.review}
                  />
                  <CInvalidFeedback>
                    {formik.errors.review}
                  </CInvalidFeedback>
                </CFormGroup>

                <CFormGroup className="mb-0">
                  <CButton
                    type="submit"
                    color="primary"
                    className="mr-1"
                    size="lg"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {formik.isSubmitting ? 'Идет отправка...' : 'Отправить'}
                  </CButton>
                </CFormGroup>
              </CForm>
            </CCardBody>
          </CCard>

        </CCol>
      </>
    );
  };

  return (
    <CleanContainer>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
        {renderContent()}
      </div>
    </CleanContainer>
  );
};

export default Review;
