import React, { useEffect, useState } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import './scss/style.scss';
import { checkAuthentication } from './store';
import PrivateRoute from './utils/PrivateRoute';
import Preloader from './components/Preloader';
import 'moment/locale/ru';
import Review from './views/review/Review';

const preloader = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

const MobileChat = React.lazy(() => import('./views/messages/MobileChat'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const RestorePassword = React.lazy(() => import('./views/pages/login/RestorePassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));

const App = () => {
  const [isInit, setIsInit] = useState(false);

  const initApp = async () => {
    await checkAuthentication();
    setIsInit(true);
  };

  useEffect(() => {
    initApp();
  }, []);

  return isInit ? (
    <BrowserRouter>
      <React.Suspense fallback={preloader}>
        <Switch>
          <Route exact path="/restorepassword" name="Восстановление пароля" render={(props) => <RestorePassword {...props} />} />
          <Route exact path="/login/:authToken?" name="Вход" render={(props) => <Login {...props} />} />
          <Route exact path="/review/:id" name="Отзыв" render={(props) => <Review {...props} />} />
          <PrivateRoute path="/mobile-chat" name="Чат" render={(props) => <MobileChat {...props} />} />
          <PrivateRoute path="/" name="Главная" render={(props) => <Layout {...props} />} />
          <Route path="*" render={(props) => <Page404 {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  ) : <Preloader />;
};

export default App;
