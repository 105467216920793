import { CSpinner } from '@coreui/react';
import React from 'react';

const Preloader = () => (
  <div className="pt-3 text-center">
    <CSpinner color="primary" grow />
  </div>
);

export default Preloader;
